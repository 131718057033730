export const FormatNumber = (number) => {
    // return new Intl.NumberFormat('en-US').format(number);
    const parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };

  export const camelToTitleCase = (camelCaseString) => {
    // Split the camelCase string on transitions from lower to upper case
    const result = camelCaseString.replace(/([a-z])([A-Z])/g, '$1 $2');
    
    // Capitalize the first letter of each word
    return result
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  export const toCamelCase = (str) => {
    return str
      .toLowerCase()                         
      .split(' ')                              
      .map((word, index) => 
        index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
      )                                        
      .join('');                             
  }