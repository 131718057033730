import DataNotFound from "../../general/DataNotFound";
import Analysis from "./Analysis";
import CompetitiveBenchmark from "./CompetitiveBenchmark";
import Engagement from "./Engagement";
function Analytics({onEditClick}) {
    return (
      <div className="p-5 lg:p-8 flex flex-col gap-8 rounded-xl bg-none gap-5">
           {/* header */}
           <div className="xs:flex-col lg:flex lg:justify-between lg:items-center">
  
                  <div className="flex flex-col">
                      <div className="font-medium text-[28px]">
                          Analytics
                      </div>
                      <div className="text-grey-2 text-[16px]">
                      Check your analytics.
                      </div>
                  
                  
                  </div>
                  <div className="flex lg:justify-end mt-5 lg:mt-0">
                      <button className="btn-sea-green-thick" onClick={onEditClick}>
                     Export
                      </button>
                  </div>
  
          </div>

          {/* Body */}

          <div className="flex flex-col gap-5">

            <Analysis/>
            <Engagement/>
            <CompetitiveBenchmark/>

          </div>

          {/* <div className="flex flex-col items-center justify-center">
            <DataNotFound/>
          </div> */}
      </div>
    );
  }
  
  export default Analytics;
  