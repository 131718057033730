import React from "react";

function CompetitiveBenchmark() {
  const data = [
    { id: 1, name: "Figma", followers: 37, views: 112 },
    { id: 2, name: "Figma", followers: 37, views: 112 },
    { id: 3, name: "Figma", followers: 37, views: 112 },
    { id: 4, name: "Figma", followers: 37, views: 112 },
    { id: 5, name: "Figma", followers: 37, views: 112 },
    { id: 6, name: "Figma", followers: 37, views: 112 },
    { id: 7, name: "Figma", followers: 37, views: 112 },
    { id: 8, name: "Figma", followers: 37, views: 112 },
  ];
  return (
    <div className="flex gap-5">
      {/* Scrollable component */}
      <div className="flex flex-col rounded-xl box-shadow4 w-3/5 p-1 ps-3 bg-white">
        <div className="flex justify-between p-5">
          <div className="font-bold">Competitive Benchmark</div>

          {/* Sort Dropdown */}
          <div className="flex items-center mb-5">
            <p>Sort by: </p>
            <div className="ml-2 p-1.5 border border-grey-7 rounded-md cursor-pointer flex items-center shadow-sm">
              Ranking
              <svg width="16" height="16" className="ml-2" viewBox="0 0 23 22">
                {/* Dropdown Arrow */}
                <path
                  d="M11.8 14.4L5.6 8.2C5.3 7.9 5.1 7.4 5.4 7.1C5.7 6.8 6.2 7 6.5 7.4L12 12.9L17.5 7.4C17.8 7 18.3 6.8 18.6 7.1C18.9 7.4 18.7 7.9 18.4 8.2L12.2 14.4C12 14.6 11.8 14.6 11.8 14.4Z"
                  fill="#222222"
                />
              </svg>
            </div>
          </div>
        </div>

        <div className="overflow-auto max-h-96">
          <table className="w-full border-separate">
            <tbody>
              {data.map((item) => (
                <tr key={item.id} className="bg-white">
                  <td className="py-3 border-b border-grey-7">{item.id}</td>
                  <td className="py-3 border-b border-grey-7 px-2 flex items-center">
                    <div className="w-12 h-12 bg-red rounded-full mr-3"></div>
                    <span>{item.name}</span>
                  </td>
                  <td className="py-3 border-b border-grey-7 px-2">
                    <span className="bg-light-sea-green border border-sea-green text-sm p-4 rounded-full">
                      {item.followers} Followers
                    </span>
                  </td>
                  <td className="py-3 border-b border-grey-7 px-2">
                    <span className="bg-blue-light border border-aqua-1 text-sm p-4 rounded-full">
                      {item.views} Views
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="flex flex-col gap-3 w-2/5">
          <div className="rounded-xl bg-light-sea-green border border-sea-green p-10 w-full box-shadow3">

              <div className="text-3xl text-dark-blue-1">
                  Product
              </div>
              <div className="font-bold text-grey-10 text-lg">
                  Most viewed section
              </div>

          </div>
          <div className="rounded-xl bg-light-sea-green border border-sea-green p-10 w-full box-shadow3">

              <div className="text-3xl text-dark-blue-1">
                 #12
              </div>
              <div className="font-bold text-grey-10 text-lg">
                  Weekly rank position
              </div>

          </div>
          <div>

          </div>
      </div>
    </div>
  );
}

export default CompetitiveBenchmark;
