// import React, { useState } from "react";
// import { Bar } from "react-chartjs-2";
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

// // Register the necessary components
// ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, Legend);


// const data = {
//     labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"],
//     datasets: [
//       {
//         label: "First dataset",
//         data: [3300, 5300, 7500, 4100, 1010, 6500, 1123, 3424, 6544, 2131, 1132, 3424],
//         fill: true,
//         backgroundColor: "rgba(75,192,192,0.2)",
//         borderColor: "rgba(75,192,192,1)"
//       },
      
//     ]
//   };

//   const options = {
//     responsive: true,
//     // maintainAspectRatio: false, // Allows the chart to fill the container without preserving the aspect ratio
//     scales: {
//     //   y: {
//     //     ticks: {
//     //       maxTicksLimit: 4, // Show only 4 ticks on the y-axis
//     //     },
//     //   },
//     y: {
//         ticks: {
//           stepSize: 1000, // Adjust based on the range of data, 60 is used here as an example

//         },

//         grid: {
//             display: false, // Disable vertical grid lines
//           },
//         suggestedMin: 0,   // Optional, set a minimum y-axis value
//         suggestedMax: 8000, // Optional, set a maximum y-axis value to ensure 4 steps
//       },
      
//       x: {
//           grid: {
//             display: false, // Disable vertical grid lines
//             drawOnChartArea : false
//           },
//         },
//     },
//     plugins: {
//       legend: {
//         display: false,
//         position: 'top',
//       },
      
//     },
//   };

// function Engagement() {
//   return (
//     <div className="flex flex-col box-shadow4 rounded-2xl">

//   <div className="p-5">
//     <Bar data={data} options={options}/>
//   </div>
// </div>
//   )
// }

// export default Engagement

import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";

// Register the necessary components with Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Engagement = () => {
  // Define chart data
  const data = {
    labels: [
      "Products",
      "People",
      "Finance",
      "Metrics",
      "Content",
      "News",
      "Awards",
      "Reference",
      "Feedback",
      "Privacy",
    ],
    datasets: [
      {
        label: "Average Engagement Time",
        data: [4, 3, 1.5, 2, 4.5, 5, 7, 3.5, 2.5, 1], // Replace with your data
        backgroundColor: [
          "rgba(0, 0, 0, 0.2)", // Default light gray color
          "rgba(0, 0, 0, 0.2)",
          "rgba(0, 0, 0, 0.2)",
          "rgba(0, 0, 0, 0.2)",
          "rgba(0, 0, 0, 0.2)",
          "rgba(0, 0, 0, 0.2)",
          "#41b6ac", // Distinct color for "Awards"
          "rgba(0, 0, 0, 0.2)",
          "rgba(0, 0, 0, 0.2)",
          "rgba(0, 0, 0, 0.2)",
        ],
        borderRadius: 4, // Rounded corners for bars
        barPercentage: 0.5, // Make bars thinner by reducing the barPercentage
        categoryPercentage: 0.6, // Increase the spacing between bars within each category

      },
    ],
  };

  // Define chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false, // Adjusts the aspect ratio to fit the container
    scales: {
      y: {
        ticks: {
          stepSize: 1, // Defines the interval between ticks on the y-axis
          font: {
            size: 12,
          },
          color: "#222222",
          padding: 10, // Add spacing around tick labels
        },
        grid: {
          display: false, // Show horizontal grid lines
          drawBorder: false,
          lineWidth: 0.5, // Thin lines
          color: "rgba(0, 0, 0, 0.1)", // Light gray color for grid lines
        },
        title: {
            display: true,
            text: "time in min", // Y-axis label
            // font: {
            //   size: 14,
            //   weight: "bold",
            // },
            color: "#333333",
            padding: {
              top: 10,
              bottom: 20,
            },
          },
        suggestedMin: 0,
        suggestedMax: 8, // Ensure the maximum value matches the chart data
      },
      x: {
        ticks: {
          font: {
            size: 12,
          },
          color: "#222222",
          padding: 10, // Add spacing around tick labels
        },
        grid: {
          display: false, // Hide vertical grid lines
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide legend by default
      },
      title: {
        display: true,
        text: "Average engagement time in section", // Chart title
        color: "#333333",
        font: {
          size: 16,
          weight: "bold",
        },
        padding: {
          top: 20,
          bottom: 50,
        },
        align: "start",
      },
      tooltip: {
        enabled: true,
        backgroundColor: "#ffffff", // White tooltip background
        titleColor: "#222222",
        bodyColor: "#222222",
        borderColor: "#e0e0e0",
        borderWidth: 1,
        padding: 10,
      },
    },
  };

  return (
    <div className="flex flex-col box-shadow4 rounded-2xl bg-white">
      {/* Chart Container */}
      <div className="p-5" style={{ height: "400px" }}>
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default Engagement;
