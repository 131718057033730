// import React, { useState } from "react";
// import { Line } from "react-chartjs-2";
// import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

// // Register the necessary components
// ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

// const data = {
//   labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"],
//   datasets: [
//     {
//       label: "First dataset",
//       data: [3300, 5300, 7500, 4100, 1010, 6500, 1123, 3424, 6544, 2131, 1132, 3424],
//       fill: true,
//       backgroundColor: "rgba(75,192,192,0.2)",
//       borderColor: "rgba(75,192,192,1)"
//     },

//   ]
// };

// const options = {
//     responsive: true,
//     // maintainAspectRatio: false, // Allows the chart to fill the container without preserving the aspect ratio
//     scales: {
//     //   y: {
//     //     ticks: {
//     //       maxTicksLimit: 4, // Show only 4 ticks on the y-axis
//     //     },
//     //   },
//     y: {
//         ticks: {
//           stepSize: 1000, // Adjust based on the range of data, 60 is used here as an example

//         },

//         grid: {
//             // display: false, // Disable vertical grid lines
//           },
//         suggestedMin: 0,   // Optional, set a minimum y-axis value
//         suggestedMax: 8000, // Optional, set a maximum y-axis value to ensure 4 steps
//       },

//       x: {
//           grid: {
//             display: false, // Disable vertical grid lines
//             drawOnChartArea : false
//           },
//         },
//     },
//     plugins: {
//       legend: {
//         display: false,
//         position: 'top',
//       },

//     },
//   };

// function Analysis() {
//     const [category, setCategory] = useState("views");
//   return (
//     <div className="flex flex-col gap-5">

//         {/* TIMEFRAME SORT DROPDOWN */}
//       <div className="flex gap-3 items-center">
//         <p>Sort by : </p>
//         <div className="flex border-grey items-center gap-2 box-shadow3 p-2 rounded-md">
//           <div>Last 60 days</div>
//           <div>
//             <svg
//               width="16"
//               height="16"
//               viewBox="0 0 23 22"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <g id="mingcute:down-line" clip-path="url(#clip0_2299_351)">
//                 <g id="Group">
//                   <path
//                     id="Vector"
//                     fill-rule="evenodd"
//                     clip-rule="evenodd"
//                     d="M11.7679 14.3981C11.596 14.5699 11.3629 14.6665 11.1199 14.6665C10.8768 14.6665 10.6437 14.5699 10.4718 14.3981L5.2862 9.21249C5.19864 9.12793 5.12881 9.02678 5.08077 8.91494C5.03273 8.80311 5.00744 8.68282 5.00638 8.56111C5.00532 8.43939 5.02852 8.31869 5.07461 8.20603C5.1207 8.09338 5.18877 7.99103 5.27483 7.90496C5.3609 7.81889 5.46325 7.75083 5.5759 7.70474C5.68856 7.65864 5.80927 7.63545 5.93098 7.63651C6.05269 7.63757 6.17298 7.66285 6.28482 7.7109C6.39665 7.75894 6.4978 7.82877 6.58236 7.91632L11.1199 12.4538L15.6574 7.91632C15.8302 7.74934 16.0618 7.65695 16.3021 7.65904C16.5425 7.66113 16.7724 7.75753 16.9424 7.92749C17.1123 8.09745 17.2087 8.32736 17.2108 8.5677C17.2129 8.80805 17.1205 9.0396 16.9535 9.21249L11.7679 14.3981Z"
//                     fill="#222222"
//                   />
//                 </g>
//               </g>
//               <defs>
//                 <clipPath id="clip0_2299_351">
//                   <rect
//                     width="22"
//                     height="22"
//                     fill="white"
//                     transform="translate(0.119629)"
//                   />
//                 </clipPath>
//               </defs>
//             </svg>
//           </div>
//         </div>
//       </div>

//       {/* LINE CHART */}
//       <div className="flex flex-col box-shadow4 rounded-2xl">

//           {/* Heading tabs */}
//         <div className="flex w-full rounded-t-2xl bg-white">
//           <div className={`w-1/5 p-5 cursor-pointer ${category === "views" && "bg-grey-1 rounded-tl-2xl border-t-4 border-t-sea-green"}`} onClick={()=>setCategory("views")}>
//             <p className="tracking-wide">PROFILE VIEWS</p>
//             <p className="text-xl font-bold">240</p>
//           </div>
//           <div className={`w-1/5 p-5 cursor-pointer ${category === "followers" && "bg-grey-1 border-t-4 border-t-sea-green"}`} onClick={()=>setCategory("followers")}>
//             <p className="text-grey-10 tracking-wide">TOTAL FOLLOWERS</p>
//             <p className="text-xl text-grey-2 font-semibold">240</p>
//           </div>
//           <div className={`w-1/5 p-5 cursor-pointer ${category === "timeSpent" && "bg-grey-1 border-t-4 border-t-sea-green"}`} onClick={()=>setCategory("timeSpent")}>
//             <p className="text-grey-10 tracking-wide">AVERAGE TIME SPENT</p>
//             <p className="text-xl text-grey-2 font-semibold">240</p>
//           </div>
//           <div className={`w-1/5 p-5 cursor-pointer ${category === "bounceRate" && "bg-grey-1 border-t-4 border-t-sea-green"}`} onClick={()=>setCategory("bounceRate")}>
//             <p className="text-grey-10 tracking-wide">BOUNCE RATE</p>
//             <p className="text-xl text-grey-2 font-semibold">240</p>
//           </div>
//           <div className={`w-1/5 p-5 cursor-pointer ${category === "meetingsBooked" && "bg-grey-1 rounded-tr-2xl border-t-4 border-t-sea-green"}`} onClick={()=>setCategory("meetingsBooked")}>
//             <p className="text-grey-10 tracking-wide">MEETINGS BOOKED</p>
//             <p className="text-xl text-grey-2 font-semibold">240</p>
//           </div>
//         </div>

//         <div className="p-5">
//           <Line data={data} options={options}/>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Analysis;

import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register the necessary Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// Data and options for the line chart
const data = {
  labels: [
    "Jan 1 - Jan 7",
    "Jan 8 - Jan 14",
    "Jan 15 - Jan 21",
    "Jan 22 - Jan 28",
    "Feb 5 - Feb 11",
    "Feb 12 - Feb 18",
    "Feb 19 - Feb 25",
    "Feb 26 - Mar 3",
    "Mar 4 - Mar 10",
    "Mar 11 - Mar 17",
    "Mar 18 - Mar 24",
  ],
  datasets: [
    {
      label: "Profile Views",
      data: [240, 800, 500, 600, 1000, 1200, 1400, 1800, 1500, 1300, 900], // Adjust values to match your screenshot
      fill: true,
      backgroundColor: "rgba(0, 196, 140, 0.1)", // Light green fill below the line
      borderColor: "#00c48c", // Green border color for the line
      borderWidth: 2, // Thickness of the line
      pointBackgroundColor: "#00c48c", // Color of the data points
      pointBorderColor: "#ffffff", // White border around the points
      tension: 0.4, // Smooth curve for the line
    },
  ],
};

const options = {
  responsive: true,
  maintainAspectRatio: false, // Maintain the correct aspect ratio
  scales: {
    y: {
      ticks: {
        display: true, // Display y-axis ticks
        padding: 10,
        font: {
          size: 12,
        },
      },
      grid: {
        display: true,
        borderDash: [8, 4], // Dashed grid line pattern
        color: "rgba(0, 0, 0, 0.1)", // Light gray color for grid lines
      },
      suggestedMin: 0, // Minimum value of the y-axis
      suggestedMax: 2000, // Adjust maximum value based on data
    },
    x: {
      ticks: {
        display: true, // Show x-axis labels
        padding: 10,
        font: {
          size: 12,
        },
      },
      grid: {
        display: false, // Hide x-axis grid lines
      },
    },
  },
  plugins: {
    legend: {
      display: false, // Hide the default legend
    },
    tooltip: {
      enabled: true,
      backgroundColor: "#ffffff", // White background for tooltips
      titleColor: "#222222", // Black title color
      bodyColor: "#222222", // Black body text color
      borderColor: "#e0e0e0", // Light gray border for tooltips
      borderWidth: 1, // Border width for tooltips
      padding: 10,
    },
  },
};

const Analysis = () => {
  return (
    <div className="flex flex-col">
      {/* Sort Dropdown */}
      <div className="flex items-center mb-5">
        <p>Sort by: </p>
        <div className="ml-2 p-2 border border-grey-7 rounded-md cursor-pointer flex items-center shadow-sm">
          Last 60 days
          <svg width="16" height="16" className="ml-2" viewBox="0 0 23 22">
            {/* Dropdown Arrow */}
            <path
              d="M11.8 14.4L5.6 8.2C5.3 7.9 5.1 7.4 5.4 7.1C5.7 6.8 6.2 7 6.5 7.4L12 12.9L17.5 7.4C17.8 7 18.3 6.8 18.6 7.1C18.9 7.4 18.7 7.9 18.4 8.2L12.2 14.4C12 14.6 11.8 14.6 11.8 14.4Z"
              fill="#222222"
            />
          </svg>
        </div>
      </div>
      <div className="flex flex-col rounded-xl box-shadow4">
        {/* TOP INFO CARDS */}
        <div className="grid grid-cols-5">
          <div className="bg-white p-4 rounded-tl-xl border-t-4 border-sea-green shadow-sm">
            <p className="text-sm text-gray-500">PROFILE VIEWS</p>
            <p className="text-2xl font-bold">240</p>
          </div>
          <div className="bg-grey-1 p-4 shadow-sm">
            <p className="text-sm text-gray-500">TOTAL FOLLOWERS</p>
            <p className="text-2xl font-bold">2k</p>
          </div>
          <div className="bg-grey-1 p-4 shadow-sm">
            <p className="text-sm text-gray-500">AVERAGE TIME SPENT</p>
            <p className="text-2xl font-bold">2.8 mins</p>
          </div>
          <div className="bg-grey-1 p-4 shadow-sm">
            <p className="text-sm text-gray-500">BOUNCE RATE</p>
            <p className="text-2xl font-bold">26%</p>
          </div>
          <div className="bg-grey-1 p-4 rounded-tr-xl shadow-sm">
            <p className="text-sm text-gray-500">MEETINGS BOOKED</p>
            <p className="text-2xl font-bold">200</p>
          </div>
        </div>

        {/* LINE CHART */}
        <div className="p-5 bg-white rounded-b-xl">
          <div className="relative" style={{ height: "400px" }}>
            <Line data={data} options={options} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analysis;
